<template>
  <div>
    <modal-header
      :title="$t('pages.tutorial.title')"
      :dir="ModalHeaderDirection.Ltr"
      relative
      @close="$router.back()"
    >
      <template #close-icon>
        <icon
          :type="IconType.Contour"
          :name="IconName.ArrowBack"
          :size="Size.S"
        />
      </template>
    </modal-header>

    <div class="tutorial-page">
      <txt :type="TxtType.Heading2">
        {{ $t('pages.tutorial.header') }}
      </txt>
      <div class="my-4">
        {{ $t('pages.tutorial.message') }}
      </div>
      <txt :type="TxtType.Heading3">
        {{ $t('pages.tutorial.rewardTitle') }}
      </txt>

      <template v-if="tutorialStages">
        <tutorial-item
          v-for="(stage, key) in tutorialStages.data"
          :key="key"
          :stage-name="key"
          :stage="stage"
          class="my-4"
        />
      </template>
    </div>
  </div>
</template>

<script lang="ts" src="./Tutorial.ts" />
<style lang="scss" src="./tutorial.scss" />
