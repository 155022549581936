import { computed, defineComponent } from 'vue';
import { RouteNames } from 'router/names';
import { ModalHeader, ModalHeaderDirection } from 'components/Modal';
import { IconName, IconType } from 'components/Icon';
import { Size } from 'core/styles';
import { TxtType } from 'components/Txt';
import { useStore } from 'vuex';
import { USER_STORE_KEY, UsersGetter } from 'store/users';
import { Nullable } from 'core/types';
import { IProfile } from 'api/users/models/user';
import TutorialItem from './components/TutorialItem';

const Tutorial = defineComponent({
  name: RouteNames.Tutorial,
  components: {
    ModalHeader,
    TutorialItem,
  },
  setup() {
    const store = useStore();
    const profile = computed<Nullable<IProfile>>(() => store.getters[`${USER_STORE_KEY}/${UsersGetter.Profile}`]);

    const tutorialStages = computed(() => profile.value?.tutorial_stages);

    const stagesArray = computed(() => (tutorialStages.value?.data ? Object.values(tutorialStages.value.data) : []));

    return {
      tutorialStages,
      stagesArray,

      ModalHeaderDirection,
      IconType,
      IconName,
      Size,
      TxtType,
    };
  },
});

export default Tutorial;
