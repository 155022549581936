<template>
  <div class="tutorial-item">
    <div
      v-for="task in stage.tasks"
      class="tutorial-item__task tutorial-item-task"
    >
      <icon
        :name="IconName.Checked"
        :type="IconType.Fill"
        :color="task.result ? MainColor.Green : MainColor.Grey4"
        class="tutorial-item-task__icon"
      />
      <div class="tutorial-item-task__text">
        <span>{{ task.title }}</span>
        <router-link
          v-if="task.link"
          :to="getRouteName(task)"
          class="tutorial-item-task__link"
        >
          {{ $t('pages.tutorial.item.to') }}
        </router-link>
      </div>
    </div>

    <btn
      w="100%"
      :type="canGetReward && !stage.reward_received ? BtnType.Primary : BtnType.Secondary"
      :disabled="!canGetReward || isFetching"
      class="mt-4"
      @click="getReward"
    >
      {{ stage.reward_title }}
    </btn>
  </div>
</template>

<script lang="ts" src="./TutorialItem.ts" />
<style lang="scss" src="./tutorialItem.scss" />
