import { computed, defineComponent, PropType } from 'vue';
import { ITutorialStage, ITutorialStageTask } from 'api/users/models/user/interfaces/ITutorialStages';
import { IconName, IconType } from 'components/Icon';
import { MainColor } from 'core/styles';
import { BtnType } from 'components/Btn';
import { TutorialLinks } from 'api/users/models/user/enums/TutorialLinks';
import { RouteNames } from 'router/names';
import { toastEmitter, ToastType } from 'components/Toasts';
import { useI18n } from 'vue-i18n';
import { useFetching } from 'composables/fetching';
import { store } from 'store/store';
import { USER_STORE_KEY, UsersAction } from 'store/users';
import { useApiErrors } from 'composables/apiErrors';

const TutorialItem = defineComponent({
  props: {
    stage: {
      type: Object as PropType<ITutorialStage>,
      required: true,
    },
    stageName: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { showError } = useApiErrors();

    function getRouteName(task: ITutorialStageTask) {
      switch (task.link) {
        case TutorialLinks.LINK_PROFILE: return { name: RouteNames.Profile };
        case TutorialLinks.LINK_RULES: return { name: RouteNames.Rules };
        case TutorialLinks.LINK_PROFILE_EDIT: return { name: RouteNames.Profile, hash: '#edit' };
        case TutorialLinks.LINK_STORIES_MODIFY: return { name: RouteNames.Catalog };
        case TutorialLinks.LINK_SUBSCRIPTIONS: return { name: RouteNames.Subscriptions };
        case TutorialLinks.LINK_TOP: return { name: RouteNames.Catalog };
        case TutorialLinks.LINK_WALLET: return { name: RouteNames.ProfileWallet };
        default: return null;
      }
    }

    const canGetReward = computed(() => props.stage.total_tasks === props.stage.completed_tasks);

    const { isFetching, fetch } = useFetching();

    async function getReward() {
      if (props.stage.reward_received) {
        toastEmitter.emit('toast', {
          type: ToastType.Error,
          message: t('pages.tutorial.alreadyReceived'),
        });
        return;
      }

      try {
        await fetch(store.dispatch(`${USER_STORE_KEY}/${UsersAction.GetReward}`, props.stageName));
        toastEmitter.emit('toast', {
          type: ToastType.Success,
          message: t('pages.tutorial.successReceived'),
        });
      } catch (e) {
        showError(e);
      }
    }

    return {
      getRouteName,
      canGetReward,
      getReward,
      isFetching,

      IconName,
      IconType,
      MainColor,
      BtnType,
    };
  },
});

export default TutorialItem;
